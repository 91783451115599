import "./App.css";

import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Contact from "./pages/Contact";
import Conventional from "./pages/Conventional";
import FHA from "./pages/FHA";
import Faq from "./pages/Faq";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import HomeBuyers from "./pages/HomeBuyers";
import Investment from "./pages/Investment";
import Jumbo from "./pages/Jumbo";
import Leadership from "./pages/Leadership";
import Navigationbar from "./components/NavDesktop";
import OurProcess from "./pages/OurProcess";
import OurStory from "./pages/OurStory";
import Refinancing from "./pages/Refinancing";
import RefinancingGuide from "./pages/RefinancingGuide";
import Renovation from "./pages/Renovation";
import Reverse from "./pages/Reverse";
import ScrollButton from "./components/ScrollButton";
import ScrollToTop from "./components/ScrollToTop";
import Sitemap from "./pages/Sitemap";
import Team from "./pages/Team";
import Va from "./pages/Va";
import LeadGenerator from './components/GenerateLead';
import Modal from './components/Modal';
import styles from "./css/banner.module.css";
import SwmcNavbar from "./components/swmcNavbar"
import { CookieSetting } from "cookiesetting-component";

function App() {
  const isSWMC = window.location.href.includes('/va-loans');
  const [loanPurpose, setLoanPurpose] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [offerLoanType, setOfferLoanType] = useState("");
  const handleModal = () => setModalShow(false);

  return (
    <React.Fragment>
      <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <ScrollToTop />
        <Modal
          size="sm"
          show={modalShow}
          onHide={handleModal}
          id="ModalWrapper"
          className={styles.modal}
        >
          <LeadGenerator
            isSWMC={isSWMC}
            loanPurpose={loanPurpose}
            offerLoanType={offerLoanType}
          />
        </Modal>
        {!isSWMC ?
          <Navigationbar
            setLoanPurpose={setLoanPurpose}
            setModalShow={setModalShow}
            setOfferLoanType={setOfferLoanType}
          />

          : <SwmcNavbar />
        }
        <Routes>
          <Route path="/" element={<Home setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="our-story" element={<OurStory setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="our-mortgage-process" element={<OurProcess setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="leadership" element={<Leadership setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="conventional-loans" element={<Conventional setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="fha-loans" element={<FHA setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="investment-property-loans" element={<Investment setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="jumbo-loans" element={<Home setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          {/* Commented Jumbo loans as per task WEB-611 */}
          {/* <Route path="jumbo-loans" element={<Jumbo setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} /> */}
          <Route path="refinancing" element={<Refinancing setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route
            path="renovation-construction-loans"
            element={<Renovation setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />}
          />
          <Route path="reverse-mortgage-hecm" element={<Reverse setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="va-loans" element={<Va isSWMC={isSWMC} setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="home-buyers-guide" element={<HomeBuyers setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="refinancing-guide" element={<RefinancingGuide setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="team" element={<Team />} />
          <Route path="contact-us" element={<Contact setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="faq" element={<Faq setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />} />
          <Route path="sitemap" element={<Sitemap />} />
        </Routes>
        <Footer isSWMC={isSWMC} setLoanPurpose={setLoanPurpose} setModalShow={setModalShow} setOfferLoanType={setOfferLoanType} />
        <ScrollButton />
      </Router>
    </React.Fragment>
  );
}

export default App;

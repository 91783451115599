import React from 'react';
import UserWayAccessibilityMenu from "./UserWayAccessibilityMenu";
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

const NavDesktop = ({
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) => {
  return (
    <header
      className="fixed-top teamNavbar fl-builder-content fl-builder-content-12 fl-builder-global-templates-locked"
      data-post-id="12"
      itemScope="itemScope"
      itemType="http://schema.org/WPHeader"
      data-type="header"
      data-sticky="0"
      data-shrink="0"
      data-overlay="0"
      data-overlay-bg="transparent">
      <div
        className="fl-row fl-row-full-width fl-row-bg-color fl-node-5bdc292c5794a"
        data-node="5bdc292c5794a">
        <div className="fl-row-content-wrap">
          <div className="uabb-row-separator uabb-top-row-separator"></div>
          <div className="fl-row-content fl-row-full-width fl-node-content">
            <div
              className="fl-col-group fl-node-5bdc295cdc1ea fl-col-group-equal-height fl-col-group-align-center"
              data-node="5bdc295cdc1ea">
              <div
                className="fl-col fl-node-5bdc295cdc3a7 fl-col-small"
                data-node="5bdc295cdc3a7">
                <div className="fl-col-content fl-node-content">
                  <div
                    id="main-logo"
                    className="fl-module fl-module-pp-image fl-node-5bdc29b03d6e0"
                    data-node="5bdc29b03d6e0">
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-photo-container">
                        <div
                          className="pp-photo pp-photo-align-left pp-photo-align-responsive-center"
                          itemScope
                          itemType="http://schema.org/ImageObject">
                          <div className="pp-photo-content ">
                            <div className="pp-photo-content-inner">
                              <Link to="/">
                                <Image
                                  className="img-fluid"
                                  src="./assest/NewSunWestLogo-1.png"
                                  alt="NewSunWestLogo-1"
                                  style={{ width: '200px' }}
                                />
                                <div className="pp-overlay-bg"></div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fl-col fl-node-5bdc295cdc427 fl-col-small"
                data-node="5bdc295cdc427">
                <div className="fl-col-content fl-node-content">
                  <div
                    id="apply-btn"
                    className="fl-module fl-module-pp-smart-button fl-node-5bdc29652086c"
                    data-node="5bdc29652086c">
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-button-wrap pp-button-width-auto pp-button-right">
                        <a
                          rel="noopener noreferrer"
                          className="pp-button"
                          role="button"
                          onClick={() => {
                            setLoanPurpose('');
                            setModalShow(true);
                            setOfferLoanType('VA');
                          }}>
                          <span className="pp-button-text">APPLY TODAY</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="header-menu-row"
                    className="fl-module fl-module-html fl-node-5bdc2b198ad94"
                    data-node="5bdc2b198ad94">
                    <div className="fl-module-content fl-node-content">
                    
                      <div className="fl-html">
                      
                        <div className="quote-menu-row">
                        
                        <div className='d-flex align-items-center justify-content-end alignmentadadesktop'>
                          <Link to="contact-us" className="free-quote-txt">
                            Free Rate Quote
                          </Link>
                          &nbsp;|&nbsp;
                          
                          <button
                            id="responsive-menu-button"
                            className="responsive-menu-button responsive-menu-boring responsive-menu-accessible"
                            type="button"
                            aria-label="Menu">
                            <span className="responsive-menu-label responsive-menu-label-left">
                              <span className="responsive-menu-button-text">Menu</span>
                            </span>
                            <span className="responsive-menu-box">
                              <span className="responsive-menu-inner"></span>
                            </span>
                          </button>
                          
                          <div>
                          <UserWayAccessibilityMenu color="black" width={40} height={40} />
                          </div>
                          </div>
                          <div id="responsive-menu-container" className="push-top">
                            <div id="responsive-menu-wrapper">
                              <ul id="responsive-menu" className="">
                                <li
                                  id="responsive-menu-item-26"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item responsive-menu-item responsive-menu-current-item">
                                  <Link to="/" className="responsive-menu-item-link">
                                    Home
                                  </Link>
                                </li>
                                <li
                                  id="responsive-menu-item-27"
                                  className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children responsive-menu-item responsive-menu-item-has-children">
                                  <a href="#" className="responsive-menu-item-link">
                                    About Us
                                    <div className="responsive-menu-subarrow"> +</div>
                                  </a>
                                  <ul className="responsive-menu-submenu responsive-menu-submenu-depth-1">
                                    <li
                                      id="responsive-menu-item-964"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link to="our-story" className="responsive-menu-item-link">
                                        Our Story
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-955"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="our-mortgage-process"
                                        className="responsive-menu-item-link">
                                        Our Mortgage Process
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-1879"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link to="leadership" className="responsive-menu-item-link">
                                        Leadership
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-971"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <a
                                        href="https://www.swmc.com/careers"
                                        target="_blank"
                                        className="responsive-menu-item-link">
                                        Careers
                                      </a>
                                    </li>
                                    {/* <li id="responsive-menu-item-954" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/real-coaching-platform/" className="responsive-menu-item-link">Real Coaching Platform</a></li> */}
                                  </ul>
                                </li>
                                <li
                                  id="responsive-menu-item-30"
                                  className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children responsive-menu-item responsive-menu-item-has-children">
                                  <a href="#" className="responsive-menu-item-link">
                                    Loan Services
                                    <div className="responsive-menu-subarrow"> +</div>
                                  </a>
                                  <ul className="responsive-menu-submenu responsive-menu-submenu-depth-1">
                                    <li
                                      id="responsive-menu-item-961"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="conventional-loans"
                                        className="responsive-menu-item-link">
                                        Conventional Loans
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-959"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link to="fha-loans" className="responsive-menu-item-link">
                                        FHA Loans
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-957"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="investment-property-loans"
                                        className="responsive-menu-item-link">
                                        Investment Property Loans
                                      </Link>
                                    </li>
                                    {/* Commented Jumbo loans as per task WEB-611 */}
                                    {/* <li
                                        id="responsive-menu-item-956"
                                        className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                        <Link
                                          to="jumbo-loans"
                                          className="responsive-menu-item-link">
                                          Jumbo Loans
                                        </Link>
                                      </li> */}
                                    <li
                                      id="responsive-menu-item-953"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="refinancing"
                                        className="responsive-menu-item-link">
                                        Refinancing
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-951"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="renovation-construction-loans"
                                        className="responsive-menu-item-link">
                                        Renovation/Construction Loans
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-950"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="reverse-mortgage-hecm"
                                        className="responsive-menu-item-link">
                                        Reverse Mortgage / HECM
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-949"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link
                                        to="https://www.swmc.com/va-loans" target="_blank"
                                        // to="va-loans"
                                        className="responsive-menu-item-link">
                                        VA Loans
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li
                                  id="responsive-menu-item-967"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children responsive-menu-item responsive-menu-item-has-children">
                                  <a
                                    href="resources/index.html"
                                    className="responsive-menu-item-link">
                                    Resources
                                    <div className="responsive-menu-subarrow"> +</div>
                                  </a>
                                  <ul className="responsive-menu-submenu responsive-menu-submenu-depth-1">
                                    <li
                                      id="responsive-menu-item-970"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children responsive-menu-item responsive-menu-item-has-children">
                                      <a
                                        href="https://swmcretail.com/getting-started/"
                                        className="responsive-menu-item-link">
                                        Getting Started
                                        <div className="responsive-menu-subarrow"> +</div>
                                      </a>
                                      <ul className="responsive-menu-submenu responsive-menu-submenu-depth-2">
                                        <li
                                          id="responsive-menu-item-958"
                                          className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                          <Link
                                            to="home-buyers-guide"
                                            className="responsive-menu-item-link">
                                            Home Buyer&#8217;s Guide
                                          </Link>
                                        </li>
                                        <li
                                          id="responsive-menu-item-34"
                                          className=" menu-item menu-item-type-custom menu-item-object-custom responsive-menu-item">
                                          <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.swmc.com/angelai/calculators"
                                            className="responsive-menu-item-link">
                                            Calculators
                                          </a>
                                        </li>
                                        <li
                                          id="responsive-menu-item-952"
                                          className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                          <Link
                                            to="refinancing-guide"
                                            className="responsive-menu-item-link">
                                            Refinancing Guide
                                          </Link>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                                <li
                                  id="responsive-menu-item-26"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item responsive-menu-item responsive-menu-current-item">
                                  <a
                                    href="https://www.swmc.com/blog/home/"
                                    target="_blank"
                                    className="responsive-menu-item-link">
                                    Blog
                                  </a>
                                </li>
                                <li
                                  id="responsive-menu-item-26"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item responsive-menu-item responsive-menu-current-item">
                                  <Link to="team" className="responsive-menu-item-link">
                                    Team
                                  </Link>
                                </li>
                                <li
                                  id="responsive-menu-item-36"
                                  className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children responsive-menu-item responsive-menu-item-has-children">
                                  <a href="#" className="responsive-menu-item-link">
                                    Contact
                                    <div className="responsive-menu-subarrow"> +</div>
                                  </a>
                                  <ul className="responsive-menu-submenu responsive-menu-submenu-depth-1">
                                    {/* <li id="responsive-menu-item-963" className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item"><a href="https://swmcretail.com/client-help/" className="responsive-menu-item-link">Client Help</a></li> */}
                                    <li
                                      id="responsive-menu-item-962"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link to="contact-us" className="responsive-menu-item-link">
                                        Contact Us
                                      </Link>
                                    </li>
                                    <li
                                      id="responsive-menu-item-960"
                                      className=" menu-item menu-item-type-post_type menu-item-object-page responsive-menu-item">
                                      <Link to="faq" className="responsive-menu-item-link">
                                        FAQ
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavDesktop;
